<template>
  <b10-list-item
    :item="formattedItem"
  >
    <v-list-item-content>
      <v-list-item-title
        class="text-wrap"
      >
        {{ formattedItem.title }}
      </v-list-item-title>
      <v-list-item-subtitle
        class="text-wrap"
      >
        {{ formattedItem.subtitle }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </b10-list-item>
</template>

<script>
import _ from '@/utils/lodash'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data () {
    return {
    }
  },
  computed: {
    formattedItem () {
      const item = _.cloneDeep(this.item)
      item.title = item.aviso
      if (item.finicio || item.ffin){
        item.subtitle = 'Valido'
        if (item.finicio) {
          item.subtitle = `${item.subtitle} desde el ${this.$options.filters.shortDate(item.finicio)}`
        }
        if (item.ffin) {
          item.subtitle = `${item.subtitle} hasta el ${this.$options.filters.shortDate(item.ffin)}`
        }
      }
      return item
    }
  },
}
</script>
